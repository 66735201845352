<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: feedLevel.vue
Description:This file is the child component of the DeviceHealth.This file gives the information about the feed blower details like feed details and functionality like search etc
-->
<template>
  <el-row class="feed-level">
    <er-data-tables
      :table-data="getTableData"
      :columns="tableColumns"
      :el-table-props="tableProps"
      :pagination-props="$constants.table['pagination-props']"
      uniqueId="feed-level"
      :actionColumn="false"
      :sortMethodForColumns="sortMethodForColumns"
      :merge="{ startColumnNumber: 1, endColumnNumber: 2 }"
      type="white-header-table"
      class="feed-level-table"
      :key="$i18n.locale"
      :searchOnTableData="getTableFilters"
    >
      <template v-slot:pond_title="{ row }">
        <er-highlight-search-component
          :highlightStrings="row.data.highlightStrings"
          :individualRowData="row.data"
          keyName="pond_title"
          :withoutHighlightStrings="row.data.pond_title"
        />
      </template>

      <template v-slot:title="{ row }">
        <er-highlight-search-component
          :highlightStrings="row.data.highlightStrings"
          :individualRowData="row.data"
          :keyName="upm__getPrefDeviceIdentityKey"
          :withoutHighlightStrings="upm__getValue(row.data)"
      /></template>
      <template v-slot:feed_percentage="{ row }">
        <el-progress
          v-if="row.data.feed_percentage >= 0"
          :percentage="+ftm__digitPrecision(row.data.feed_percentage, 1)"
        ></el-progress>
        <el-tag v-else type="mini">{{ $t("Comn_na") }}</el-tag>
      </template>
      <template v-slot:device_type="{ row }">
        <er-highlight-search-component
          :highlightStrings="row.data.highlightStrings"
          :individualRowData="row.data"
          keyName="device_type_lang"
          :withoutHighlightStrings="row.data.device_type_lang"
        />
      </template>
      <template v-slot:status="{ row }">
        <span :style="generateColorForStatus(row.data)">
          {{ row.data.comm_status }}
        </span>
      </template>
      <template v-slot:time="{ row }">
        {{ lastCommunicationDate(row.data) }}
      </template>
    </er-data-tables>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  name: "FeedLevel",
  mixins: [datesHandlerMixin, filtersMixin, userPreferenceMixin],
  inject: ["parentConfig"],
  data: function() {
    return {
      tableProps: {
        // border: true,
        size: "small",
        defaultSort: {
          prop: "pond_title",
          order: "ascending"
        },
        height: "calc(100vh - var(--height-gutter))"
      },
      tableData: [],
      pondSearch: ""
    };
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("pond", {
      getPondMotherDeviceStatus: "getPondMotherDeviceStatus",
      getFeedBlowerDeviceStatus: "getFeedBlowerDeviceStatus"
    }),
    getSelectedStatus() {
      return this.parentConfig.selectedStatus;
    },
    getTableFilters() {
      return this.parentConfig.searchOnData;
    },
    lastCommunicationDate() {
      return data => {
        if (
          data.last_communicated_at &&
          this.dhm__dateUtilsLib.isValid(new Date(data.last_communicated_at))
        ) {
          return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
            data.utc_last_communicated_at,
            this.upm__getFormatDateAndTimeString,
            this.getUserTimeZoneString,
            this.dhm__getLocaleObj
          );
        } else {
          return "--";
        }
      };
    },
    generateColorForStatus() {
      return data => {
        const deviceStatus = data.comm_status;
        const obj = {
          "En línea": { color: "Green", "font-weight": 550 },
          Online: { color: "Green", "font-weight": 550 },
          Offline: { color: "#bdbdbd", "font-weight": 550 },
          Desconectada: { color: "#bdbdbd", "font-weight": 550 }
        };
        return obj[deviceStatus];
      };
    },
    getDeviceNameUnits() {
      return this.getPreferredUnits.device_name === "alias_name"
        ? "title"
        : "code";
    },

    tableColumns() {
      return {
        pond_title: {
          prop: "pond_title",
          label: this.$t("Comn_pond_name"),
          sortable: "custom",
          align: "left",
          minWidth: "150",
          enableSearch: true,
          searchProperty: "pond_title"
        },
        device_type: {
          prop: "device_type",
          label: this.$t("Comn_pond_devices_type"),
          minWidth: "150",
          enableSearch: true,
          searchProperty: "device_type_lang"
        },
        title: {
          prop: "device_sort_key",
          label: this.$t("Comn_Device_ID"),
          sortable: "custom",
          minWidth: "150",
          enableSearch: true,
          searchProperty: this.upm__getPrefDeviceIdentityKey
        },
        feed_percentage: {
          prop: "feed_percentage",
          label: `${this.$t("Comn_feed")} (%)`,
          minWidth: "200",
          sortable: true,
          sortMethod: (a, b) => {
            return this.$commonUtils.numericComparator(
              a.feed_percentage,
              b.feed_percentage
            );
          }
        },
        status: {
          prop: "status",
          label: this.$t("Comn_status"),
          minWidth: "100",
          enableSearch: true,
          searchProperty: "comm_status"
        },
        time: {
          prop: "time",
          label: this.$t("Comn_time"),
          minWidth: "150"
        }
      };
    },

    getPonds() {
      return this.$store.getters["pond/getPondsObj"];
    },
    getTableData() {
      const searchedPonds = [
        ...this.getPondMotherDeviceStatus(this.getSelectedStatus),
        ...this.getFeedBlowerDeviceStatus(this.getSelectedStatus)
      ].filter(pondRec => {
        return pondRec.feed_percentage >= 0;
      });
      return searchedPonds;
    },
    sortMethodForColumns: function() {
      return {
        pond_title: this.$commonUtils.alphaNumericComparator,
        device_sort_key: this.$commonUtils.alphaNumericComparator
      };
    }
  },
  methods: {
    feed_percentage_formatter(value) {
      if (value === "-") {
        return value;
      }
      var filling_value = value / 2;
      filling_value = 50 - filling_value;
      var text_position_value = -7.691;
      if (value < 10) {
        text_position_value = -3.691;
      } else if (value < 100) {
        text_position_value = -4.691;
      }
      return {
        filling_value,
        text_position_value
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.table) {
        console.log(this.$refs.table.$refs.dataTables.$refs.elTable);
        this.$refs.table.$refs.dataTables.$refs.elTable.doLayout();
      }
    });
    this.upm__setDataKeys("code", "title");
  }
};
</script>

<style lang="scss">
.feed-level {
  @include responsiveProperty(--height-gutter, 197px, 205px, 215px);
  .el-progress-bar {
    padding-right: 100px;
    margin-right: -100px;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"feed-level"},[_c('er-data-tables',{key:_vm.$i18n.locale,staticClass:"feed-level-table",attrs:{"table-data":_vm.getTableData,"columns":_vm.tableColumns,"el-table-props":_vm.tableProps,"pagination-props":_vm.$constants.table['pagination-props'],"uniqueId":"feed-level","actionColumn":false,"sortMethodForColumns":_vm.sortMethodForColumns,"merge":{ startColumnNumber: 1, endColumnNumber: 2 },"type":"white-header-table","searchOnTableData":_vm.getTableFilters},scopedSlots:_vm._u([{key:"pond_title",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"pond_title","withoutHighlightStrings":row.data.pond_title}})]}},{key:"title",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":_vm.upm__getPrefDeviceIdentityKey,"withoutHighlightStrings":_vm.upm__getValue(row.data)}})]}},{key:"feed_percentage",fn:function(ref){
var row = ref.row;
return [(row.data.feed_percentage >= 0)?_c('el-progress',{attrs:{"percentage":+_vm.ftm__digitPrecision(row.data.feed_percentage, 1)}}):_c('el-tag',{attrs:{"type":"mini"}},[_vm._v(_vm._s(_vm.$t("Comn_na")))])]}},{key:"device_type",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"device_type_lang","withoutHighlightStrings":row.data.device_type_lang}})]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{style:(_vm.generateColorForStatus(row.data))},[_vm._v("\n        "+_vm._s(row.data.comm_status)+"\n      ")])]}},{key:"time",fn:function(ref){
var row = ref.row;
return [_vm._v("\n      "+_vm._s(_vm.lastCommunicationDate(row.data))+"\n    ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"battery-component",attrs:{"element-loading-background":"white"}},[(_vm.loading)?_c('Loader'):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('el-row',{staticClass:"battery"},[_c('er-data-tables',{key:_vm.$i18n.locale,staticClass:"battery-table",attrs:{"table-data":_vm.tableDataArr,"columns":_vm.tableColumns,"el-table-props":_vm.tableProps,"pagination-props":_vm.$constants.table['pagination-props'],"uniqueId":"battery-details","merge":{ startColumnNumber: 1, endColumnNumber: 2 },"actionColumn":false,"sortMethodForColumns":_vm.sortMethodForColumns,"type":"white-header-table","searchOnTableData":_vm.getTableFilters},scopedSlots:_vm._u([{key:"pond_title",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"pond_title","withoutHighlightStrings":row.data.pond_title}})]}},{key:"title",fn:function(ref){
var row = ref.row;
return [(row.data.device_type === _vm.$constants.deviceTypes.SHRIMP_TALK.key)?[_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":_vm.getDeviceNameUnits,"withoutHighlightStrings":_vm.upm__getValue(row.data)}})]:[_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":_vm.getDeviceNameUnits,"keyValueBasedOnPrefferedUnits":true,"withoutHighlightStrings":_vm.upm__getValue(row.data)}})]]}},{key:"device_type",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"device_type_lang","withoutHighlightStrings":row.data.device_type_lang}})]}},{key:"battery_voltage",fn:function(ref){
var row = ref.row;
return [_c('span',{style:(_vm.generateColorForBatterVoltage(row.data))},[_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(_vm.batteryVoltage(row.data)))+"\n        ")])]}},{key:"time",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.lastCommunicationDate(row.data))+"\n      ")]}},{key:"comm_status",fn:function(ref){
var row = ref.row;
return [_c('span',{style:(_vm.generateColorForStatus(row.data))},[_vm._v("\n          "+_vm._s(row.data.comm_status)+"\n        ")])]}}],null,false,1574263034)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }